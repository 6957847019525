import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import PropTypes from "prop-types";

import './checkbox.scss';

export const Checkbox = forwardRef((props, ref) => {
  const [checkboxState, setCheckboxState] = useState(false);
  const [checkboxErrorState, setCheckBoxErrorState] = useState(false);
  const { initialState, isActive, isUnactive, onChange } = props;

  useEffect(() => {
    setCheckboxState(initialState);
  }, [initialState])

  useImperativeHandle(ref, () => ({
    getValue: () => {
      return checkboxState;
    },
    showError: () => {
      setCheckBoxErrorState(true);
    }
  }));

  const handleClickEvent = () => {
    if(checkboxState) {
      isUnactive();
      setCheckboxState(false);
      if(onChange) {
        onChange(false)
      }
    }
    else {
      isActive();
      setCheckboxState(true);
      if(onChange) {
        onChange(true)
      }
    }
    setCheckBoxErrorState(false)
  }

  return (
    <button
      type="button"
      className="checkbox-container"
      style = {{backgroundColor: checkboxErrorState ? 'red' : 'transparent'}}
      onClick={() => { props.onClick(); handleClickEvent(); }}
    >
      <div className="checkbox center-content relative">
        <img
          src={require('../../assets/images/choose/checkbox_off.svg')}
          style={{ opacity: checkboxState ? 0 : 1 }}
          alt="checkbox off"
          className="checkbox-icon animatable-400 ease-in-out-quart"
        />
        <img
          src={require('../../assets/images/choose/checkbox_on.svg')}
          style={{ opacity: checkboxState ? 1 : 0 }}
          alt="checkbox on"
          className="checkbox-icon animatable-400 ease-in-out-quart"
        />
      </div>
    </button>
  );
});

export const CheckboxRound = (props) => {
  const [checkboxState, setCheckboxState] = useState(false);
  const { checkState, setCheckState, onClick, secondary } = props;

  useEffect(() => {
    if(checkState !== null) {
      setCheckboxState(checkState)
    }
  }, [checkState])

  const handleButtonClick = () => {
    setCheckboxState(!checkState);
    onClick(!checkState)
    if(secondary) {
      setCheckState(checkState);
    }
    else {
      setCheckState(!checkState)
    }
  }

  return (
    <button type="button" className="checkbox-round-container " onClick={handleButtonClick}>
      <div className="checkbox-round-main center-content">
        <div className="checkbox-round-center animatable-400 ease-in-out-quart" style={{ opacity: checkboxState ? 1 : 0 }} />
      </div>
    </button>
  );
};

Checkbox.defaultProps = {
  initialState: false,
  isActive:  () => {},
  isUnactive: () => {},
  onClick: () => {},
}

Checkbox.propTypes = {
  initialState: PropTypes.bool,
  isActive: PropTypes.func,
  isUnactive: PropTypes.func,
  onClick: PropTypes.func,
}

CheckboxRound.propTypes = {
  linkedValue: PropTypes.bool,
  setLinkedValue: PropTypes.func,
}

CheckboxRound.defaultProps = {
  linkedValue: null,
  setLinkedValue: () => {},
}