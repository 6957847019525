import axios from 'axios';
import { merge, get } from 'lodash';
import { defaultUrl } from "../api/routes";

import storage from '../storage';
// import history from '../utils/history';
import transformKeys from '../transformKeys';

// const defaultUrl = 'https://backend.develop.bim.flykube.agifly.cloud/api/v1';
// const defaultUrl = "http://localhost:3000/api/v1/";

const getHeaders = (headers) => {
  const user = storage.get('user');
  const defaultHeaders = {
    Accept: 'application/vnd.cia.v1+json',
    'Content-Type': 'application/vnd.cia.v1+json',
  };

  if (user && user.token) {
    merge(headers, { Authorization: `Bearer ${get(user, 'auth.token')}` });
  }

  return merge({}, defaultHeaders, headers);
};

/* axios.interceptors.response.use(
  (res) => res,
  (error) => {
    switch (error.response.status) {
      case 401:
        store.dispatch(refreshToken());
        // history.push('/unauthorized');
        window.location.reload();
        break;
      case 404:
        history.push('/resource-not-found');
        break;
      case 500:
      case 504:
        history.push('/internal-server-error');
        break;
      default:
        break;
    }
    return Promise.reject(error);
  },
); */

const request = {
  get: (url, headers = {}) => {
    return axios({
      method: 'GET',
      url: `${defaultUrl}${url}`,
      headers: getHeaders(headers),
      transformResponse: axios.defaults.transformResponse
        .concat((data) => transformKeys.toCamelCase(data)),
    });
  },
  post: (url, payload, headers = {}, transform = true) => {
    return axios({
      method: 'POST',
      url: `${defaultUrl}${url}`,
      data: transform ? transformKeys.toSnakeCase(payload) : payload,
      headers: getHeaders(headers),
      transformResponse: axios.defaults.transformResponse
        .concat((data) => transformKeys.toCamelCase(data)),
    });
  },
  patch: (url, payload, headers = {}, transform = true) => {
    return axios({
      method: 'PATCH',
      url: `${defaultUrl}${url}`,
      data: transform ? transformKeys.toSnakeCase(payload) : payload,
      headers: getHeaders(headers),
      transformResponse: axios.defaults.transformResponse
        .concat((data) => transformKeys.toCamelCase(data)),
    });
  },
  put: (url, payload, headers = {}, transform = true) => {
    return axios({
      method: 'PUT',
      url: `${defaultUrl}${url}`,
      data: transform ? transformKeys.toSnakeCase(payload) : payload,
      headers: getHeaders(headers),
      transformResponse: axios.defaults.transformResponse
        .concat((data) => transformKeys.toCamelCase(data)),
    });
  },
  delete: (url, headers = {}) => {
    return axios({
      method: 'DELETE',
      url: `${defaultUrl}${url}`,
      headers: getHeaders(headers),
      transformResponse: axios.defaults.transformResponse
        .concat((data) => transformKeys.toCamelCase(data)),
    });
  },
};

export default request;
